export const getCustomFragment = (key) => {
  switch (key) {
    case `PuxDesignTopImage`:
      return `
        ... on Orchard_PuxDesignTopImage {
          contentItemId
          contentType
          displayText
          puxDesignTopImageDescription {
            html
          }
          metadata {
            alignment
            size
          }
          widgetBasicProperties {
            widgetBasicPropertiesID
            widgetBasicPropertiesMarginBottom
            widgetBasicPropertiesMarginTop
            widgetBasicPropertiesPaddingTop
            widgetBasicPropertiesPaddingBottom
          }
          puxMediaSelector {
            puxMediaSelectorImage {
              resizePaths(width: 1920, mode: "Min", quality: 75)
              resizePathsMobile(width: 768, mode: "Min", quality: 75)
              resizePathsTablet(width: 1200, mode: "Min", quality: 75)
            }
          }
        }
      `

    case `PuxDesignLogos`:
      return `
          ... on Orchard_PuxDesignLogos {
            contentType
            contentItemId
            bag {
                contentItems {
                    ... on Orchard_PuxDesignLogo {
                      displayText
                      contentItemId
                      logoLink {
                        url
                        internal
                        text
                      }
                      puxMediaSelector {
                        puxMediaSelectorImage {
                          resizePaths(width: 400, mode: "Min")
                        }
                      }
                    }
                }
            }
            widgetBasicProperties {
                widgetBasicPropertiesID
                widgetBasicPropertiesMarginBottom
                widgetBasicPropertiesMarginTop
                widgetBasicPropertiesPaddingBottom
                widgetBasicPropertiesPaddingTop
            }
            metadata {
                alignment
                size
            }
          }
        `

    case `PuxDesignPersons`:
      return `
          ... on Orchard_PuxDesignPersons {
            contentItemId
            contentType
            metadata {
              alignment
              size
            }
            widgetBasicProperties {
              widgetBasicPropertiesID
              widgetBasicPropertiesMarginBottom
              widgetBasicPropertiesMarginTop
              widgetBasicPropertiesPaddingTop
              widgetBasicPropertiesPaddingBottom
            }
            puxDesignPersonsList {
              contentItems {
                ... on Orchard_PuxDesignPerson {
                  displayText
                  puxDesignPersonPhone
                  puxDesignPersonMail
                  puxDesignPersonPosition
                  puxMediaSelector {
                    puxMediaSelectorImage {
                      resizePaths(width: 80)
                    }
                  }
                }
              }
            }
          }
        `

    case `PuxDesignTestimonial`:
      return `
          ... on Orchard_PuxDesignTestimonial {
            contentType
            contentItemId
            metadata {
              alignment
              size
            }
            puxDesignTestimonialQuotation {
              html
            }
            widgetBasicProperties {
              widgetBasicPropertiesID
              widgetBasicPropertiesMarginBottom
              widgetBasicPropertiesMarginTop
              widgetBasicPropertiesPaddingBottom
              widgetBasicPropertiesPaddingTop
            }
            puxDesignTestimonialPerson {
              contentItems {
                ... on Orchard_PuxDesignPerson {
                  displayText
                  puxDesignPersonPhone
                  puxDesignPersonMail
                  puxDesignPersonPosition
                  puxMediaSelector {
                    puxMediaSelectorImage {
                      resizePaths(width: 80)
                    }
                  }
                }
              }
            }
          }
        `

    case `PuxDesignImage`:
      return `
          ... on Orchard_PuxDesignImage {
            contentType
            contentItemId
            alt
            metadata {
              alignment
              size
            }
            puxMediaSelector {
              puxMediaSelectorImage {
                resizePaths(width: 1920, mode: "Min", quality: 75)
                resizePathsMobile(width: 768, mode: "Min", quality: 75)
                resizePathsTablet(width: 1200, mode: "Min", quality: 75)
              }
            }
            widgetBasicProperties {
              widgetBasicPropertiesID
              widgetBasicPropertiesMarginBottom
              widgetBasicPropertiesMarginTop
              widgetBasicPropertiesPaddingBottom
              widgetBasicPropertiesPaddingTop
            }
          }
        `

    case `PuxButton`:
      return `
          ... on Orchard_PuxButton {
            metadata {
              alignment
              size
            }
            widgetBasicProperties {
                widgetBasicPropertiesID
                widgetBasicPropertiesMarginBottom
                widgetBasicPropertiesMarginTop
                widgetBasicPropertiesPaddingBottom
                widgetBasicPropertiesPaddingTop
            }
            type
            target
            size
            puxButtonLink {
              internal
              text
              url
            }
            contentType
            contentItemId
          }
        `

    case `Trumbowyg`:
      return `
          ... on Orchard_Trumbowyg {
            contentType
            contentItemId
            isNarrow
            content  {
              html
            }
            widgetBasicProperties {
                widgetBasicPropertiesID
                widgetBasicPropertiesMarginBottom
                widgetBasicPropertiesMarginTop
                widgetBasicPropertiesPaddingBottom
                widgetBasicPropertiesPaddingTop
            }
            metadata {
                alignment
                size
            }
          }
        `

    case `PuxDesignCounters`:
      return `
          ... on Orchard_PuxDesignCounters {
            contentType
            contentItemId
            puxDesignVariantSelector {
              puxDesignVariantSelector
            }
            bag {
                contentItems {
                    ... on Orchard_PuxDesignCounterItem {
                        puxDesignCounterItemBoldText
                        puxDesignCounterItemDescription
                        puxDesignCounterItemNumber
                    }
                }
            }
            widgetBasicProperties {
                widgetBasicPropertiesID
                widgetBasicPropertiesMarginBottom
                widgetBasicPropertiesMarginTop
                widgetBasicPropertiesPaddingBottom
                widgetBasicPropertiesPaddingTop
            }
            metadata {
                alignment
                size
            }
          }
        `

    case `PuxDesignSlider`:
      return `
          ... on Orchard_PuxDesignSlider {
            contentType
            contentItemId
            bag {
                contentItems {
                    ... on Orchard_PuxDesignSliderItem {
                      displayText
                      puxDesignSliderItemImage {
                        resizePaths(width: 1920, mode: "Min", quality: 80)
                        resizePathsMobile(width: 768, mode: "Min", quality: 80)
                        resizePathsTablet(width: 1200, mode: "Min", quality: 80)
                      }
                    }
                }
            }
            widgetBasicProperties {
              widgetBasicPropertiesID
              widgetBasicPropertiesMarginBottom
              widgetBasicPropertiesMarginTop
              widgetBasicPropertiesPaddingBottom
              widgetBasicPropertiesPaddingTop
            }
            metadata {
              alignment
              size
            }
          }
        `

    case `PuxDesignTestimonialSlider`:
      return `
        ... on Orchard_PuxDesignTestimonialSlider {
          contentType
          contentItemId
          puxDesignTestimonialSliderPerex {
            html
          }
          bag {
              contentItems {
                  ... on Orchard_PuxDesignTestimonialSliderItem {
                    displayText
                    puxDesignTestimonialSliderItemImage {
                      resizePaths(width: 1920, mode: "Min", quality: 80)
                      resizePathsMobile(width: 768, mode: "Min", quality: 80)
                      resizePathsTablet(width: 1200, mode: "Min", quality: 80)
                    }
                    puxDesignTestimonialSliderItemText {
                      html
                    }
                    puxDesignTestimonialSliderItemName
                    puxDesignTestimonialSliderItemPosition
                  }
              }
          }
          widgetBasicProperties {
            widgetBasicPropertiesID
            widgetBasicPropertiesMarginBottom
            widgetBasicPropertiesMarginTop
            widgetBasicPropertiesPaddingBottom
            widgetBasicPropertiesPaddingTop
          }
          metadata {
            alignment
            size
          }
        }
      `

    case `PuxDesignIconsText`:
      return `
          ... on Orchard_PuxDesignIconsText {
            contentItemId
            contentType
            puxDesignIconsTextSmallerIcons
            puxDesignVariantSelector {
              puxDesignVariantSelector
            }
            bag {
              contentItems {
                ... on Orchard_PuxDesignIconText {
                  puxDesignIconTextText {
                    html
                  }
                  contentItemId
                  puxDesignIconSelector {
                    puxDesignIconSelectorIcon
                    puxDesignIconSelectorColor
                  }
                  iconTextButton {
                    contentItems {
                      ... on Orchard_PuxButton {
                        metadata {
                          alignment
                          size
                        }
                        widgetBasicProperties {
                            widgetBasicPropertiesID
                            widgetBasicPropertiesMarginBottom
                            widgetBasicPropertiesMarginTop
                            widgetBasicPropertiesPaddingBottom
                            widgetBasicPropertiesPaddingTop
                        }
                        type
                        target
                        size
                        puxButtonLink {
                          internal
                          text
                          url
                        }
                        contentType
                        contentItemId
                      }
                    }
                  }
                  iconTextTestimonial {
                    contentItems {
                      ... on Orchard_PuxDesignTestimonial {
                        contentType
                        contentItemId
                        metadata {
                          alignment
                          size
                        }
                        puxDesignTestimonialQuotation {
                          html
                        }
                        widgetBasicProperties {
                          widgetBasicPropertiesID
                          widgetBasicPropertiesMarginBottom
                          widgetBasicPropertiesMarginTop
                          widgetBasicPropertiesPaddingBottom
                          widgetBasicPropertiesPaddingTop
                        }
                        puxDesignTestimonialPerson {
                          contentItems {
                            ... on Orchard_PuxDesignPerson {
                              displayText
                              puxDesignPersonPhone
                              puxDesignPersonMail
                              puxDesignPersonPosition
                              puxMediaSelector {
                                puxMediaSelectorImage {
                                  resizePaths(width: 80)
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            widgetBasicProperties {
              widgetBasicPropertiesID
              widgetBasicPropertiesMarginBottom
              widgetBasicPropertiesMarginTop
              widgetBasicPropertiesPaddingBottom
              widgetBasicPropertiesPaddingTop
            }
            metadata {
              alignment
              size
            }
          }
        `

    case `PuxDesignService`:
      return `
          ... on Orchard_PuxDesignService {
            contentType
            contentItemId
            puxDesignServicePDF {
              urls
            }
            puxDesignServiceBTNLink
            puxDesignServiceBTNText
            puxDesignServiceDescription {
              html
            }
            puxDesignServicePriceFrom
            puxDesignServiceService
            puxDesignServiceTooltip {
              html
            }
            widgetBasicProperties {
              widgetBasicPropertiesID
              widgetBasicPropertiesMarginBottom
              widgetBasicPropertiesMarginTop
              widgetBasicPropertiesPaddingBottom
              widgetBasicPropertiesPaddingTop
            }
            metadata {
              alignment
              size
            }
          }
        `

    case `PuxDesignReferences`:
      return `
          ... on Orchard_PuxDesignReferences {
            contentItemId
            contentType
            puxDesignReferencesIsSlider
            metadata {
              alignment
              size
            }
            widgetBasicProperties {
              widgetBasicPropertiesID
              widgetBasicPropertiesMarginBottom
              widgetBasicPropertiesMarginTop
              widgetBasicPropertiesPaddingBottom
              widgetBasicPropertiesPaddingTop
            }
            bag {
              contentItems {
                ... on Orchard_PuxDesignReferenceWidgetItem {
                  contentItemId                      
                  puxDesignReferenceWidgetItemCustomTitle
                  puxDesignReferenceWidgetItemCustomDescription {
                    html
                  }
                  puxDesignReferenceWidgetItemReference {
                    contentItems {
                      ... on Orchard_PuxDesignReference {
                        createdUtc
                        displayText
                        path
                        puxDesignReferenceType
                        puxDesignReferenceDisplayTitle
                        puxDesignReferenceDescription {
                          html
                        }
                        referencePreviewText {
                          html
                        }
                        referenceCategory {
                          termContentItems {
                            displayText
                          }
                        }
                        puxDesignReferenceLaptopImage {
                          puxMediaSelectorImage {
                            resizePaths(width: 1920, mode: "Min", quality: 100)
                            resizePathsMobile(width: 768, mode: "Min", quality: 100)
                            resizePathsTablet(width: 1200, mode: "Min", quality: 100)
                          }
                        }
                        puxDesignReferencePreviewImage {
                          puxMediaSelectorImage {
                            resizePaths(width: 600, mode: "Min", quality: 75)
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `

    case `PuxDesignHint`:
      return `
            ... on Orchard_PuxDesignHint {
              contentType
              contentItemId
              hint {
                html
              }
              widgetBasicProperties {
                  widgetBasicPropertiesID
                  widgetBasicPropertiesMarginBottom
                  widgetBasicPropertiesMarginTop
                  widgetBasicPropertiesPaddingBottom
                  widgetBasicPropertiesPaddingTop
              }
              metadata {
                  alignment
                  size
              }
            }
          `

    case `PuxDesignTimelineWidget`:
      return `
              ... on Orchard_PuxDesignTimelineWidget {
                contentType
                contentItemId              
                widgetBasicProperties {
                    widgetBasicPropertiesID
                    widgetBasicPropertiesMarginBottom
                    widgetBasicPropertiesMarginTop
                    widgetBasicPropertiesPaddingBottom
                    widgetBasicPropertiesPaddingTop
                }
                metadata {
                    alignment
                    size
                }
                timelineWidgetItems {
                  contentItems {
                    ... on Orchard_PuxDesignTimeline {
                      displayText
                      timelineClients
                      timelineCount
                      timelineDescription {
                        html
                      }
                      timelineLocation
                      timelineTurnover
                      contentItemId
                    }
                  }
                }
              }
            `

    case `PuxDesignVideoWidget`:
      return `
        ... on Orchard_PuxDesignVideoWidget {
          contentType
          contentItemId   
          videoWidgetHideUI
          videoWidgetSourceUrl
          videoWidgetAutoplay
          videoWidgetUseLightbox
          videoWidgetFile {
            puxMediaSelectorImage {
              paths
              resizePaths(width: 1920, mode: "Min", quality: 80)
            }
          }
          videoWidgetThumbnail {
            puxMediaSelectorImage {
              resizePaths(width: 1920, mode: "Min", quality: 80)
              resizePathsMobile(width: 768, mode: "Min", quality: 80)
              resizePathsTablet(width: 1200, mode: "Min", quality: 80)
            }
          }
          widgetBasicProperties {
              widgetBasicPropertiesID
              widgetBasicPropertiesMarginBottom
              widgetBasicPropertiesMarginTop
              widgetBasicPropertiesPaddingBottom
              widgetBasicPropertiesPaddingTop
          }
          metadata {
              alignment
              size
          }
        }
      `

    case `PuxCustomHTML`:
      return `
        ... on Orchard_PuxCustomHTML {
          contentType
          contentItemId   
          customHTMLContent {
            html
          }
          widgetBasicProperties {
              widgetBasicPropertiesID
              widgetBasicPropertiesMarginBottom
              widgetBasicPropertiesMarginTop
              widgetBasicPropertiesPaddingBottom
              widgetBasicPropertiesPaddingTop
          }
          metadata {
              alignment
              size
          }
        }
      `

    case `PuxDesignCTABlockWidget`:
      return `
        ... on Orchard_PuxDesignCTABlockWidget {
          contentType
          contentItemId
          cTABlockWidgetContent {
            html
          }
          cTABlockWidgetImage {
            puxMediaSelectorImage {
              resizePaths(width: 1920, mode: "Min", quality: 80)
              resizePathsMobile(width: 768, mode: "Min", quality: 80)
              resizePathsTablet(width: 1200, mode: "Min", quality: 80)
            }
          }
          cTABlockLink {
            internal
            url
            text
          }
          widgetBasicProperties {
              widgetBasicPropertiesID
              widgetBasicPropertiesMarginBottom
              widgetBasicPropertiesMarginTop
              widgetBasicPropertiesPaddingBottom
              widgetBasicPropertiesPaddingTop
          }
          metadata {
              alignment
              size
          }
        }
      `

    default:
      return `contentType`
  }
}
