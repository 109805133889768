import axios from 'axios'
import React, { FunctionComponent } from 'react'
import getTokenData from '../../utils/getGraphqlValidationToken'

import Loader from '../../components/layout/Loader'
import Career from '../career'
import { CareerProps, CareerPost } from '../../types/career.types'

const PreviewCareer: FunctionComponent<any> = props => {
  const pageData = props.data.orchard.puxDesignCareer[0]
  const [completeData, setCompleteData] = React.useState<any>()

  const query = `
  {
    puxDesignCareer(where: {contentItemId: "${pageData.contentItemId}", contentItemVersionId: "${pageData.contentItemVersionId}"} status: DRAFT) {
      path
      displayText
      careerNoOffersText {
        html
      }
      careerJobOffers {
        contentItems {
          ... on PuxDesignJobOffer {
            contentItemId
            jobOfferPreviewText
            path
            displayText
          }
        }
      }
    }
  }
  `

  React.useEffect(async () => {
    const tokenData = await getTokenData()

    axios
      .post(
        `${process.env.GATSBY_API_URL}`,
        {
          query: query,
        },
        {
          headers: {
            'Content-Type': `application/json`,
            'Access-Control-Allow-Origin': `*`,
            Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
          },
        }
      )
      .then(response => {
        if (response.status === 200) {
          const documentData: CareerPost = response.data.data.puxDesignCareer[0]
          const data = { ...props }
          data.data.orchard.puxDesignCareer = [documentData]


          // TODO: remove when we will support multiple widget zones...
          data.pageContext.widgetsTop = data.pageContext.widgets.careerWidgetsTop
          data.pageContext.widgetsBottom = data.pageContext.widgets.careerWidgetsBottom

          // only for career start
          // fake related articles
          // data.pageContext.relatedCareerPosts.data.orchard.career = [documentData]
          // only for career end

          setCompleteData(data)
        }
      })
      .catch(error => {
        console.log(`Preview Data Fetch Error:`, error)
      })
  }, [])

  return completeData ? <Career {...completeData} /> : <Loader message="Loading Article Draft Data" />
}

export default PreviewCareer
