import axios from 'axios'
import React, { FunctionComponent } from 'react'
import getTokenData from '../../utils/getGraphqlValidationToken'

import Loader from '../../components/layout/Loader'
import HomePage from '../homepage'
import { HomePageProps, HomePagePost } from '../../types/homePage.types'

const PreviewHomePage: FunctionComponent<any> = (props) => {
  const pageData = props.data.orchard.homePage[0]
  const [completeData, setCompleteData] = React.useState<any>()

  const query = `
  {
    homePage(where: {contentItemId: "${pageData.contentItemId}", contentItemVersionId: "${pageData.contentItemVersionId}"} status: DRAFT) {
      homePageHeading
        homePagePerex {
          html
        }
        homePageTechnologyText {
          html
        }
        bag {
          contentItems {
            contentItemId
            ... on PuxDesignHPCTA {
              newLink {
                internal
                url
                text
              }
              puxDesignHPCTADescription {
                html
              }
              puxDesignHPCTABTNText
              puxDesignIconSelector {
                puxDesignIconSelectorIcon
                puxDesignIconSelectorColor
              }
              bag {
                contentItems {
                  contentItemId
                  ... on PuxDesignLogo {
                    contentItemId
                    logoLink {
                      internal
                      url
                      text
                    }
                    puxMediaSelector {
                      puxMediaSelectorImage {
                        resizePaths(width: 400, mode: "Min")
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `

  React.useEffect(async () => {
    const tokenData = await getTokenData()

    axios
      .post(
        `${process.env.GATSBY_API_URL}`,
        {
          query: query,
        },
        {
          headers: {
            'Content-Type': `application/json`,
            'Access-Control-Allow-Origin': `*`,
            Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
          },
        }
      )
      .then((response) => {
        
        if (response.status === 200) {
          const documentData: HomePagePost = response.data.data.homePage[0]
          const data = { ...props }

          data.data.orchard.homePage = [documentData]

          

          // TODO: remove when we will support multiple widget zones...
          data.pageContext.widgets = data.pageContext.widgets.widgetZone

          // only for homePage start
          // fake related articles
          // data.pageContext.relatedHomePagePosts.data.orchard.homePage = [documentData]
          // only for homePage end

          setCompleteData(data)
        }
      })
      .catch((error) => {
        console.log(`Preview Data Fetch Error:`, error)
      })
  }, [])

  return completeData ? <HomePage {...completeData} /> : <Loader message="Loading Article Draft Data" />
}

export default PreviewHomePage
