import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout/layout'
import PuxMetaTags from '../components/shared/PuxMetaTags'
import WidgetBuilder from '../components/builders/widget-builder'
import AdamPhoto from '../img/adam.png'
import JirkaPhoto from '../img/jirka.png'
import { Icon } from '../components/builders/icon-builder'
import { t } from 'ttag'
import FormBuilder from '../components/builders/form-builder'
import BreadCrumbs from '../components/BreadCrumbs'

const Contact = ({ data, pageContext }: any) => {
  const page = data.orchard.puxDesignContactPage[0]

  return (
    <Layout localizedPath={page.localization?.localizations}>
      <PuxMetaTags {...pageContext.metaTagsData} />
      <BreadCrumbs
        default={true}
        center={false}
        items={[
          {
            path: page.path,
            displayText: page.displayText,
          },
        ]}
      />

      <div className="pux-container center-content">
        <h1>{page.puxDesignContactPageDisplayTitle ? page.puxDesignContactPageDisplayTitle : page.displayText}</h1>
      </div>

      <div className="alternate-section-wrap mt-60">
        <div className="pux-container">
          <div className="alternate-section">
            <div className="alternate-section-left">
              <h2>{t`PuxDesign.Contact.TalkToHelpdesk`}</h2>
              <div className="perex">{t`PuxDesign.Contact.HelpdeskPerex`}</div>
              <div className="info-tom">
                <img src={AdamPhoto} alt="Adam Cibulka"></img>
                <strong>Adam Cibulka</strong>
              </div>
              <div className="info-tom-phone">
                <a href="tel:+420777789878">
                  <Icon id="mobile" color="#3883c4" />
                  +420 777 789 878
                </a>
              </div>
              <div className="info-tom-mail">
                <a href="mailto:helpdesk@puxdesign.cz">
                  <Icon id="mail" color="#3883c4" />
                  <span className="btn-link">helpdesk@puxdesign.cz</span>
                </a>
              </div>
            </div>
            <div className="alternate-section-right mobile-pt-0">
              <h2>{t`PuxDesign.Contact.TalkToJiri`}</h2>
              <div className="perex">{t`PuxDesign.Contact.JirkaPerex`}</div>
              <div className="info-tom">
                <img src={JirkaPhoto} alt="Jiří Juhańák"></img>
                <strong>Jiří Juhaňák</strong>
              </div>
              <div className="info-tom-phone">
                <a href="tel:+420605175519">
                  <Icon id="mobile" color="#3883c4" />
                  +420 605 175 519
                </a>
              </div>
              <div className="info-tom-mail">
                <a href="mailto:jiri.juhanak@puxdesign.cz">
                  <Icon id="mail" color="#3883c4" />
                  <span className="btn-link">jiri.juhanak@puxdesign.cz</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-form-section-wrap">
        <div className="pux-container">
          <h2>{page.puxDesignContactPageFormTitle}</h2>
          <div className="perex">{page.puxDesignContactPageFormText}</div>
          <FormBuilder formId="general" recaptchaUrl={pageContext.recaptchaUrl} />
        </div>
      </div>
      <div className="alternate-section-wrap">
        <div className="pux-container">
          <div className="alternate-section">
            <div className="alternate-section-left">
              <h2>BiQ pux a.s.</h2>
              <a
                href="https://www.google.com/maps/place/PUXdesign+%E2%80%94+Weby,+e-shopy+a+port%C3%A1ly+na+m%C3%ADru/@49.1904285,16.6189417,17z/data=!3m1!4b1!4m6!3m5!1s0x471294457313f187:0x7dc23d5d63eeae3b!8m2!3d49.1904285!4d16.6215166!16s%2Fg%2F1td416nb?entry=ttu"
                className="btn-link small"
                target="_blank"
                rel="noopener"
              >{t`PuxDesign.Contact.Address`}</a>
              <table>
                <tbody>
                  <tr>
                    <td>{t`PuxDesign.Contact.ICO`}</td>
                    <td>{t`PuxDesign.Contact.ICOValue`}</td>
                  </tr>
                  <tr>
                    <td>{t`PuxDesign.Contact.DIC`}</td>
                    <td>{t`PuxDesign.Contact.DICValue`}</td>
                  </tr>
                </tbody>
              </table>
              <div>{t`PuxDesign.Contact.BankTransfer`}</div>
              <div>{t`PuxDesign.Contact.IBAN`}</div>
              <div>{t`PuxDesign.Contact.BIC`}</div>
              <small>{t`PuxDesign.Contact.PUXDescription`}</small>
            </div>
          </div>
        </div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2607.5340095241954!2d16.6215166!3d49.190428499999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471294457313f187%3A0x7dc23d5d63eeae3b!2sPUXdesign%20%E2%80%94%20Weby%2C%20e-shopy%20a%20port%C3%A1ly%20na%20m%C3%ADru!5e0!3m2!1scs!2scz!4v1689674017679!5m2!1scs!2scz"
          style={{ "border": 0 }}
          loading='lazy'
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>

      <div className="contact-widgets">
        <WidgetBuilder widgetBuilderData={pageContext.widgets} />
      </div>
    </Layout>
  )
}

export default Contact

export const query = graphql`
  query($pagePath: String = "") {
    orchard {
      puxDesignContactPage(first: 1, where: { path: $pagePath }) {
        contentItemId
        path
        displayText
        puxDesignContactPageDisplayTitle
        puxDesignContactPageFormText
        puxDesignContactPageFormTitle
        localization {
          localizations {
            ... on Orchard_PuxDesignContactPage {
              path
              published
              localization {
                culture
              }
            }
          }
        }
      }
    }
  }
`
