import React from 'react'
import { graphql, Link } from 'gatsby'
import { BlogPageProps } from '../types/blog.types'
import Layout from '../components/layout/layout'
import BlogPost from '../components/blog/blog-post'
import '../sass/blog/listing.scss'
import { t } from 'ttag'
import { IntersectionObserver } from '../components/shared/intersection-observer'
import BreadCrumbs from '../components/BreadCrumbs'
import PuxMetaTags from '../components/shared/PuxMetaTags'
import { getLocalizedUrl } from '../utils/localeURL'

export const query = graphql`
  query($pagePath: String = "", $isoCode: String = "") {
    orchard {
      puxDesignBlogCategory(first: 1, where: { path: $pagePath }) {
        path
        displayText
        contentItemId
        localization {
          localizations {
            ... on Orchard_PuxDesignBlogCategory {
              path
              published
              localization {
                culture
              }
            }
          }
        }
      }
      blog(where: { localization: { culture: $isoCode } }, orderBy: { createdUtc: DESC }) {
        displayText
        path
        contentItemId
        perex {
          html
        }
        blogAuthor {
          contentItems(first: 1) {
            displayText
          }
        }
        createdUtc
        puxMediaSelector {
          puxMediaSelectorImage {
            resizePaths(width: 1920, mode: "Min", quality: 75)
            resizePathsMobile(width: 768, mode: "Min", quality: 75)
            resizePathsTablet(width: 1200, mode: "Min", quality: 75)
          }
        }
        puxCategory {
          termContentItems {
            displayText
          }
        }
      }
    }
  }
`

const Blog = ({ data, pageContext }: BlogPageProps) => {
  const page = data
  const currentCategory = data.orchard.puxDesignBlogCategory[0].displayText;

  return (
    <Layout localizedPath={page.localization?.localizations}>
      <PuxMetaTags {...pageContext.metaTagsData} />
      <BreadCrumbs
        default={true}
        center={false}
        items={[
          {
            path: page.orchard.puxDesignBlogCategory[0]?.path,
            displayText: page.orchard.puxDesignBlogCategory[0]?.displayText,
          },
        ]}
      />
      <h1 className="blog-title">{page.orchard.puxDesignBlogCategory[0]?.displayText}</h1>

      <div className="blog-tags">
        {pageContext.allBlogCategories && pageContext.allBlogCategories.map(category => {
          if (category.displayText === `Blog`) {
            return (
              <Link
                className="blog-tag"
                activeClassName="is-active"
                key={category.contentItemId}
                to={getLocalizedUrl(category.path)}
              >
                {t`PuxDesign.Blog.All`}
              </Link>
            )
          } else {
            return (
              <Link
                className="blog-tag"
                activeClassName="is-active"
                key={category.contentItemId}
                to={getLocalizedUrl(category.path)}
              >
                {category.displayText}
              </Link>
            )
          }
        })}
      </div>

      <div className="blog-listing">
        {currentCategory === "Blog" ? page.orchard.blog.map((post, index) => {
          if (index <= 1) {
            return (
              <div key={post.contentItemId} className="blog-listing-item blog-listing-item-big">
                <BlogPost type="big" props={post} />
              </div>
            )
          }
          return (
            <div key={post.contentItemId} className="blog-listing-item blog-listing-item-small">
              <BlogPost type="small" props={post} />
            </div>
          )
        }) : page.orchard.blog
          .filter(post => post.puxCategory.termContentItems
            .filter(category => category.displayText === currentCategory).length > 0)
          .map((post, index) => {
            if (index <= 1) {
              return (
                <div key={post.contentItemId} className="blog-listing-item blog-listing-item-big">
                  <BlogPost type="big" props={post} />
                </div>
              )
            }
            return (
              <div key={post.contentItemId} className="blog-listing-item blog-listing-item-small">
                <BlogPost type="small" props={post} />
              </div>
            )
          })}
      </div>
    </Layout>
  )
}

export default Blog
