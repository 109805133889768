import "./SideBanner.scss"
import React, { FunctionComponent } from 'react'
import { Link } from 'gatsby'
import Picture from '../shared/picture'
import { getHrefLang, getLocalizedUrl } from '../../utils/localeURL'
import PuxTrumbowyg from "../shared/pux-trumbowyg"

const SideBanner: FunctionComponent<any> = ({ props }) => {
    function sideBannerLink() {
        if (!props.sideBannerLink) {
            return null
        }

        if (props.sideBannerLink.internal[0]) {
            return (
                <Link className="btn btn-primary" hrefLang={getHrefLang(props.sideBannerLink.url[0])} to={getLocalizedUrl(props.sideBannerLink.url[0])}>
                    {props.sideBannerLink.text[0]}
                </Link>
            )
        } else {
            return (
                <a className="btn btn-primary" href={props.sideBannerLink.url[0]}>
                    {props.sideBannerLink.text[0]}
                </a>
            )
        }
    }

    return (
        <div className="side-banner">
            <div className="side-banner-image">
                {props.sideBannerImage !== null && (
                    <Picture puxMediaSelectorImage={props.sideBannerImage.puxMediaSelectorImage} alt={props.displayText} />
                )}
            </div>
            <div className="side-banner-content">
                <PuxTrumbowyg content={props.sideBannerContent} />
                {sideBannerLink()}
            </div>
        </div>
    )
}

export default SideBanner
