import axios from 'axios'
import React, { FunctionComponent } from 'react'
import getTokenData from '../../utils/getGraphqlValidationToken'

import Loader from '../../components/layout/Loader'
import Blog from '../blog'
import { BlogPageProps, BlogPost } from '../../types/blog.types'

const PreviewBlog: FunctionComponent<BlogPageProps> = props => {
  const pageData = props.data.orchard.puxDesignBlogCategory[0]
  const [completeData, setCompleteData] = React.useState<BlogPageProps>()

  const query = `
    {
      puxDesignBlogCategory(where: {contentItemId: "${pageData.contentItemId}", contentItemVersionId: "${pageData.contentItemVersionId}"} status: DRAFT) {
        displayText
        createdUtc
        path
        contentItemId
        author
        puxCategory {
          termContentItems {
            displayText
          }
        }
      }
      blog(orderBy: { createdUtc: DESC }) {
        displayText
        path
        contentItemId
        perex {
          html
        }
        blogAuthor {
          contentItems(first: 1) {
            displayText
          }
        }
        createdUtc
        puxMediaSelector {
          puxMediaSelectorImage {
            resizePaths(width: 1920, mode: "Min", quality: 75)
            resizePathsMobile(width: 768, mode: "Min", quality: 75)
            resizePathsTablet(width: 1200, mode: "Min", quality: 75)
          }
        }
        puxCategory {
          termContentItems {
            displayText
          }
        }
      }
    }
  `

  React.useEffect(async () => {
    const tokenData = await getTokenData()

    console.log(query)

    axios
      .post(
        `${process.env.GATSBY_API_URL}`,
        {
          query: query,
        },
        {
          headers: {
            'Content-Type': `application/json`,
            'Access-Control-Allow-Origin': `*`,
            Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
          },
        }
      )
      .then(response => {
        if (response.status === 200) {
          console.log(response)
          const documentData: [BlogPost] = response.data.data.blog
          const data = { ...props }
          data.data.orchard.blog = documentData


          // TODO: remove when we will support multiple widget zones...
          //data.pageContext.widgets = data.pageContext.widgets.widgetZone

          // only for blog start
          // fake related articles
          // data.pageContext.relatedBlogPosts.data.orchard.blog = [documentData]
          // only for blog end

          console.log(data)
          setCompleteData(data)
        }
      })
      .catch(error => {
        console.log(`Preview Data Fetch Error:`, error)
      })
  }, [])

  return completeData ? <Blog {...completeData} /> : <Loader message="Loading Article Draft Data" />
}

export default PreviewBlog
