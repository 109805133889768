import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout/layout'
import WidgetBuilder from '../components/builders/widget-builder'
import { BlogAuthor, BlogDetailProps } from '../types/blog.types'
import BlogPost from '../components/blog/blog-post'
import Moment from 'react-moment'
import Picture from '../components/shared/picture'
import PuxTrumbowyg from '../components/shared/pux-trumbowyg'
import '../sass/blog/detail.scss'
import '../sass/blog/post.scss'
import BreadCrumbs from '../components/BreadCrumbs'
import PuxMetaTags from '../components/shared/PuxMetaTags'
import PuxEcomailForm from '../components/PuxEcomailForm/PuxEcomailForm'
import { t } from 'ttag'
import SideBanner from '../components/SideBanner/SideBanner'
import ServiceNav from '../components/ServiceNav'

export const query = graphql`
  query($pagePath: String = "") {
    orchard {
      blog(where: { path: $pagePath }) {
        displayText
        perex {
          html
        }
        createdUtc
        modifiedUtc
        path
        contentItemId
        blogAuthor {
          contentItems(first: 1) {
            displayText
          }
        }
        puxMediaSelector {
          puxMediaSelectorImage {
            resizePaths(width: 1920, mode: "Min", quality: 75)
            resizePathsMobile(width: 768, mode: "Min", quality: 75)
            resizePathsTablet(width: 1200, mode: "Min", quality: 75)
          }
        }
        puxCategory {
          termContentItems {
            displayText
          }
        }
        localization {
          localizations {
            ... on Orchard_Blog {
              path
              published
              localization {
                culture
              }
            }
          }
        }
        blogSideBanner {
          contentItems {
            displayText
            ... on Orchard_PuxDesignSideBanner {
              createdUtc
              displayText
              sideBannerContent {
                html
              }
              sideBannerLink {
                internal
                text
                url
              }
              sideBannerImage {
                puxMediaSelectorImage {
                  resizePaths(width: 1920, mode: "Min", quality: 75)
                  resizePathsMobile(width: 768, mode: "Min", quality: 75)
                  resizePathsTablet(width: 1200, mode: "Min", quality: 75)
                }
              }
            }
          }
        }
      }
      puxCrossDivisionNavigation(where: {alias: {alias: "blog-cross-division-navigation"}}) {
        crossDivisionNavigation {
          contentItems {
            ... on Orchard_PuxDesignServiceNavigationItem {
              puxDesignServiceNavigationItemCustomDescription
              puxDesignServiceNavigationItemCustomTitle
              puxDesignServiceNavigationItemService {
                contentItems {
                  ... on Orchard_PuxDesignServicePage {
                    displayText
                    puxDesignServicePageDescription
                    path
                    puxDesignIconSelector {
                      puxDesignIconSelectorIcon
                    }
                    puxDesignThemeSelector {
                      puxDesignThemeSelectorTheme
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

function getBlogJson(item) {
  const json = {
    '@context': `https://schema.org`,
    '@type': `NewsArticle`,
    headline: item.displayText,
    image: [
      item.puxMediaSelector?.puxMediaSelectorImage.resizePaths[0] ?? ``,
      item.puxMediaSelector?.puxMediaSelectorImage.resizePathsTablet[0] ?? ``,
      item.puxMediaSelector?.puxMediaSelectorImage.resizePathsMobile[0] ?? ``,
    ],
    datePublished: item.createdUtc,
    dateModified: item.modifiedUtc ?? ``,
  }

  return json
}

export default function BlogDetail({ data, pageContext }: BlogDetailProps) {
  const blogData = data.orchard.blog[0]
  const navigationData = data.orchard.puxCrossDivisionNavigation[0]

  return (
    <Layout localizedPath={blogData.localization?.localizations}>
      <script type="application/ld+json">{JSON.stringify(getBlogJson(blogData))}</script>
      <PuxMetaTags {...pageContext.metaTagsData} />
      <BreadCrumbs items={pageContext.breadcrumbs} center={false} />
      <div className="blog-background">

        <div className="pux-container">
          <div className="blog-grid">
            <div className="blog-article">
              <h1>{blogData.displayText}</h1>
              <small className="blog-info">
                <div className="post-tags">
                  {blogData.puxCategory.termContentItems.map(item => (
                    <div className="post-tag">
                      <span>{item.displayText}</span>
                    </div>
                  ))}
                </div>
                <div>
                  <Moment format="DD - MM - YYYY" date={blogData.createdUtc} />
                  <span> | </span>
                  {blogData.blogAuthor.contentItems[0].displayText}
                </div>
              </small>
              <div className="blog-image">
                <Picture
                  puxMediaSelectorImage={blogData.puxMediaSelector.puxMediaSelectorImage}
                  alt={blogData.displayText}
                />
              </div>
              <PuxTrumbowyg content={blogData.perex} />
              <WidgetBuilder widgetBuilderData={pageContext.widgets} />
            </div>
            <aside className="blog-side">
              {blogData?.blogSideBanner?.contentItems?.map((item, idx) => (
                <SideBanner key={idx} props={item} />
              )
              )}
              {/* {pageContext.relatedBlogPosts?.data?.orchard?.blog?.map(post => {
              return (
                <div key={post.contentItemId} className="blog-related-item-small">
                <BlogPost type="small" props={post} />
                </div>
                )
              })} */}
            </aside>
          </div>
        </div>
      </div>
      <div className="blog-subscribe">
        <PuxEcomailForm action='https://puxdesign.ecomailapp.cz/public/subscribe/7/c01458c4e85a58f0047857fac06ae283' />
      </div>
      <section className="blog-navigation landingPage-navigation">
        <div className='pux-container'>
          <h2 className="center-content h3">{t`PuxDesign.Blog.Navigation.Title`}</h2>
        </div>
        <ServiceNav {...navigationData.crossDivisionNavigation}></ServiceNav>
      </section>
      <section className='landingPage-socials'>
        <img className='landingPage-socials-background' loading='lazy' src={`${process.env.GATSBY_ORCHARD_API_URL + '/media/enterprise%20landing%20page'}/PuxSocialsBanner.png`} alt='' />
        <div className='landingPage-socials-content'>
          <div className='pux-container'>
            <h2 className='landingPage-socials-title'>{t`PuxDesign.LandingPage.Socials.Title`}</h2>
            <div className='landingPage-socials-perex'>{t`PuxDesign.LandingPage.Socials.Perex`}</div>
            <div className='landingPage-socials-buttons'>
              <a href={t`PuxDesign.LandingPage.Socials.Instagram`} target='_blank' className='landingPage-socials-button-instagram'></a>
              <a href={t`PuxDesign.LandingPage.Socials.Facebook`} target='_blank' className='landingPage-socials-button-facebook'></a>
              <a href={t`PuxDesign.LandingPage.Socials.Linkedin`} target='_blank' className='landingPage-socials-button-linkedin'></a>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
