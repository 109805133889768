import axios from 'axios'
import React, { FunctionComponent } from 'react'
import getTokenData from '../../utils/getGraphqlValidationToken'

import Loader from '../../components/layout/Loader'
import HowDoWeWork from '../howdowework'
import { HowDoWeWorkProps, HowDoWeWorkPost } from '../../types/howDoWeWork.types'

const PreviewHowDoWeWork: FunctionComponent<any> = props => {
  const pageData = props.data.orchard.puxDesignHowDoWeWork[0]
  const [completeData, setCompleteData] = React.useState<any>()

  const query = `
  {
    puxDesignHowDoWeWork(where: {contentItemId: "${pageData.contentItemId}", contentItemVersionId: "${pageData.contentItemVersionId}"} status: DRAFT) {
      displayText
      path
      puxDesignHowDoWeWorkDisplayTitle
      puxDesignHowDoWeWorkPMDescription
      puxDesignHowDoWeWorkPMDuration
      puxDesignHowDoWeWorkPMOutput
      puxDesignHowDoWeWorkCollapses {
        contentItems {
          ... on PuxDesignCollapseItem {
            contentItemId
            puxDesignCollapseItemColor
            puxDesignCollapseItemDescription {
              html
            }
            puxDesignCollapseItemDisplayTitle
            puxDesignCollapseItemDuration
            puxDesignCollapseItemOutput
            puxDesignCollapseItemIsOptional
            puxDesignCollapseItemtOpened
            puxDesignCollapseItemNestedCollapses {
              contentItems {
                ... on PuxDesignCollapseItem {
                  contentItemId
                  puxDesignCollapseItemColor
                  puxDesignCollapseItemDescription {
                    html
                  }
                  puxDesignCollapseItemDisplayTitle
                  puxDesignCollapseItemDuration
                  puxDesignCollapseItemOutput
                  puxDesignCollapseItemIsOptional
                  puxDesignCollapseItemtOpened
                }
              }
            }
          }
        }
      }
    }
  }
  `

  React.useEffect(async () => {
    const tokenData = await getTokenData()

    axios
      .post(
        `${process.env.GATSBY_API_URL}`,
        {
          query: query,
        },
        {
          headers: {
            'Content-Type': `application/json`,
            'Access-Control-Allow-Origin': `*`,
            Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
          },
        }
      )
      .then(response => {
        if (response.status === 200) {
          const documentData: HowDoWeWorkPost = response.data.data.puxDesignHowDoWeWork[0]
          const data = { ...props }

          data.data.orchard.puxDesignHowDoWeWork = [documentData]

          // TODO: remove when we will support multiple widget zones...
          data.pageContext.widgets = data.pageContext.widgets.puxDesignHowDoWeWorkWidgets

          // only for howDoWeWork start
          // fake related articles
          // data.pageContext.relatedHowDoWeWorkPosts.data.orchard.howDoWeWork = [documentData]
          // only for howDoWeWork end

          setCompleteData(data)
        }
      })
      .catch(error => {
        console.log(`Preview Data Fetch Error:`, error)
      })
  }, [])

  return completeData ? <HowDoWeWork {...completeData} /> : <Loader message="Loading Article Draft Data" />
}

export default PreviewHowDoWeWork
