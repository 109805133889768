import React from 'react'
import { graphql } from 'gatsby'
//import preval from 'babel-plugin-preval'

import Layout from '../components/layout/layout'
import PuxMetaTags from '../components/shared/PuxMetaTags'
import WidgetBuilder from '../components/builders/widget-builder'
import BreadCrumbs from '../components/BreadCrumbs'

export const query = graphql`
  query($pagePath: String = "") {
    orchard {
      pUXDesignPage(first: 1, where: { path: $pagePath }) {
        path
        displayText
        localization {
          localizations {
            ... on Orchard_PUXDesignPage {
              path
              published
              localization {
                culture
              }
            }
          }
        }
      }
    }
  }
`

const Default = ({ data, pageContext }: any) => {
  const page = data.orchard.pUXDesignPage[0]

  return (
    <Layout localizedPath={page.localization?.localizations}>
      <PuxMetaTags {...pageContext.metaTagsData} />
      <BreadCrumbs items={pageContext.breadcrumbs} center={false} />
      <WidgetBuilder widgetBuilderData={pageContext.widgets} />
    </Layout>
  )
}

export default Default
