import axios from 'axios'
import React, { FunctionComponent } from 'react'
import getTokenData from '../../utils/getGraphqlValidationToken'

import Loader from '../../components/layout/Loader'
import Default from '../default'

const PreviewDefault: FunctionComponent<any> = (props) => {
  const pageData = props.data.orchard.pUXDesignPage[0]
  const [completeData, setCompleteData] = React.useState<any>()

  const query = `
  {
    pUXDesignPage(where: {contentItemId: "${pageData.contentItemId}", contentItemVersionId: "${pageData.contentItemVersionId}"} status: DRAFT) {
      path
      displayText
      localization {
        localizations {
          ... on PUXDesignPage {
            path
            published
            localization {
              culture
            }
          }
        }
      }
    }
  }
  `

  React.useEffect(async () => {
    const tokenData = await getTokenData()

    axios
      .post(
        `${process.env.GATSBY_API_URL}`,
        {
          query: query,
        },
        {
          headers: {
            'Content-Type': `application/json`,
            'Access-Control-Allow-Origin': `*`,
            Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          const documentData = response.data.data.pUXDesignPage[0]
          const data = { ...props }
          data.data.orchard.homePage = [documentData]

          // TODO: remove when we will support multiple widget zones...
          data.pageContext.widgets = data.pageContext.widgets.widgetZone

          // only for homePage start
          // fake related articles
          // data.pageContext.relatedHomePagePosts.data.orchard.homePage = [documentData]
          // only for homePage end

          setCompleteData(data)
        }
      })
      .catch((error) => {
        console.log(`Preview Data Fetch Error:`, error)
      })
  }, [])

  return completeData ? <Default {...completeData} /> : <Loader message="Loading PuxPage Draft Data" />
}

export default PreviewDefault
