import axios from 'axios'
import React, { FunctionComponent } from 'react'
import getTokenData from '../../utils/getGraphqlValidationToken'

import Loader from '../../components/layout/Loader'
import ReferenceDetail from '../reference'
import { ReferencePageProps, Reference } from '../../types/reference.types'
import CaseStudy from '../caseStudy'

const PreviewReferenceDetail: FunctionComponent<ReferencePageProps> = (props) => {
  const pageData = props.data.orchard.puxDesignReference[0]
  const [completeData, setCompleteData] = React.useState<ReferencePageProps>()

  const query = `
    {
        puxDesignReference(where: {contentItemId: "${pageData.contentItemId}", contentItemVersionId: "${pageData.contentItemVersionId}"} status: DRAFT) {
            displayText
            caseStudyNavigation {
              contentItems {
                ... on PuxDesignNavigationItem {
                  displayText
                  puxDesignNavigationItemText
                  puxDesignNavigationItemAnchor
                  contentItemId
                }
              }
            }
            puxDesignReferenceDescription {
              html
            }
            puxDesignReferenceDisplayTitle
            puxDesignReferenceIndustryFocus
            puxDesignReferenceDisplayPrice
            puxDesignReferenceNewLink {
              internal
              text
              url
            }
            puxDesignReferenceTopImagePicture {
              puxMediaSelectorImage {
                resizePaths(width: 1920, mode: "Min", quality: 75)
                resizePathsMobile(width: 768, mode: "Min", quality: 75)
                resizePathsTablet(width: 1200, mode: "Min", quality: 75)
              }
            }
            puxDesignReferenceCompanyLogo {
              resizePathsTablet(width: 100, quality: 80, mode: "Max")
              resizePathsMobile(width: 80, quality: 80, mode: "Max")
              resizePaths(width: 120, quality: 80, mode: "Max")
            }
            puxDesignReferenceTopImageText {
              html
            }
            puxDesignReferenceConditionBefore {
              html
            }
            puxDesignReferenceConditionAfter {
              html
            }
            puxDesignReferenceBottomDescription {
              html
            }
            path
            puxDesignReferenceType
            puxDesignReferencePriceList {
              contentItems {
                createdUtc
                displayText
                modifiedUtc
                publishedUtc
              }
            }
            referenceAppliedServices {
              contentItems {
                ... on PuxDesignServiceNavigationItem {
                  createdUtc
                  displayText
                  puxDesignServiceNavigationItemCustomDescription
                  puxDesignServiceNavigationItemCustomTitle
                  puxDesignServiceNavigationItemService {
                    contentItems {
                      ... on PuxDesignServicePage {
                        displayText
                        puxDesignIconSelector {
                          puxDesignIconSelectorIcon
                        }
                        path
                        puxDesignThemeSelector {
                          puxDesignThemeSelectorTheme
                        }
                      }
                    }
                  }
                }
              }
            }
        }
      }
  `

  React.useEffect(async () => {
    const tokenData = await getTokenData()

    axios
      .post(
        `${process.env.GATSBY_API_URL}`,
        {
          query: query,
        },
        {
          headers: {
            'Content-Type': `application/json`,
            'Access-Control-Allow-Origin': `*`,
            Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          const documentData: Reference = response.data.data.puxDesignReference[0]
          const data = { ...props }
          data.data.orchard.puxDesignReference = [documentData]
          data.pageContext.widgets = data.pageContext.widgets.referenceWidgets
          setCompleteData(data)
        }
      })
      .catch((error) => {
        console.log(`Preview Data Fetch Error:`, error)
      })
  }, [])

  if (completeData) {
    if (completeData.data.orchard.puxDesignReference[0].puxDesignReferenceType === `casestudy`) {
      return <CaseStudy {...completeData} />
    }
    if (completeData.data.orchard.puxDesignReference[0].puxDesignReferenceType === `reference`) {
      return <ReferenceDetail {...completeData} />
    }
    return <Loader message="Reference Data Corrupted" />
  }

  return <Loader message="Loading Article Draft Data" />
}

export default PreviewReferenceDetail
