import axios from 'axios'
import React, { FunctionComponent } from 'react'
import getTokenData from '../../utils/getGraphqlValidationToken'

import Loader from '../../components/layout/Loader'
import BlogDetail from '../blog-detail'
import { BlogDetailProps, BlogPost } from '../../types/blog.types'

const PreviewBlogDetail: FunctionComponent<BlogDetailProps> = props => {
  const pageData = props.data.orchard.blog[0]
  const [completeData, setCompleteData] = React.useState<BlogDetailProps>()

  const query = `
    {
        blog(where: {contentItemId: "${pageData.contentItemId}", contentItemVersionId: "${pageData.contentItemVersionId}"} status: DRAFT) {
          displayText
          perex {
            html
          }
          createdUtc
          path
          contentItemId
          blogAuthor {
            contentItems(first: 1) {
              displayText
            }
          }
          puxMediaSelector {
            puxMediaSelectorImage {
              resizePaths(width: 1920, mode: "Min", quality: 75)
              resizePathsMobile(width: 768, mode: "Min", quality: 75)
              resizePathsTablet(width: 1200, mode: "Min", quality: 75)
            }
          }
          puxCategory {
            termContentItems {
              displayText
            }
          }
        }
        puxCrossDivisionNavigation(where: {alias: {alias: "blog-cross-division-navigation"}}) {
          crossDivisionNavigation {
            contentItems {
              ... on PuxDesignServiceNavigationItem {
                puxDesignServiceNavigationItemCustomDescription
                puxDesignServiceNavigationItemCustomTitle
                puxDesignServiceNavigationItemService {
                  contentItems {
                    ... on PuxDesignServicePage {
                      displayText
                      puxDesignServicePageDescription
                      path
                      puxDesignIconSelector {
                        puxDesignIconSelectorIcon
                      }
                      puxDesignThemeSelector {
                        puxDesignThemeSelectorTheme
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
  `

  React.useEffect(async () => {
    const tokenData = await getTokenData()

    axios
      .post(
        `${process.env.GATSBY_API_URL}`,
        {
          query: query,
        },
        {
          headers: {
            'Content-Type': `application/json`,
            'Access-Control-Allow-Origin': `*`,
            Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
          },
        }
      )
      .then(response => {
        if (response.status === 200) {
          const documentData: BlogPost = response.data.data.blog[0]
          const data = { ...props }
          data.data.orchard.blog = [documentData]
          data.data.orchard.puxCrossDivisionNavigation = [response.data.data.puxCrossDivisionNavigation[0]]


          // TODO: remove when we will support multiple widget zones...
          data.pageContext.widgets = data.pageContext.widgets.mainWidgetZone

          // only for blog start
          // fake related articles
          // data.pageContext.relatedBlogPosts.data.orchard.blog = [documentData]
          // only for blog end

          setCompleteData(data)
        }
      })
      .catch(error => {
        console.log(`Preview Data Fetch Error:`, error)
      })
  }, [])

  return completeData ? <BlogDetail {...completeData} /> : <Loader message="Loading Article Draft Data" />
}

export default PreviewBlogDetail
