import './PuxEcomailForm.scss'

import React, { FunctionComponent } from 'react'
import icon from "../../img/Mail.svg"

interface PuxEcomailFormType {
  action: string
}

const PuxEcomailForm: FunctionComponent<PuxEcomailFormType> = (props) => (
  <div className='eco-form'>
    <div className='pux-container'>
      <div className='eco-form-box'>
        <div className='eco-form-title'>
          <div className='eco-form-icon'>
            <img src={icon} alt="" />
          </div>
          <h2>
            <strong>Buďte v obraze ve světě webů a e-shopů</strong>
            <br />
            Přihlaste se k odběru našeho newsletteru
          </h2>
        </div>
        <div className='eco-form-subtitle'>
          Každé 3 měsíce vám pošleme oběžník s horkými informacemi ze světa vývoje a webdesignu, novinkami v e-commerce sféře i akčními nabídkami pro ještě lepší web.
        </div>
        <form method="post" action={props.action}>
          <div className="eco-input-group">
            <div className="eco-input required">
              <label htmlFor="name">Jméno a příjmení</label>
              <input required type="text" name="name" placeholder='Vaše jméno a příjmení' />
            </div>
            <div className="eco-input required">
              <label htmlFor="email">e-mail</label>
              <input required type="email" name="email" placeholder='Váš e-mail' />
            </div>
          </div>
          <button className="eco-submit" type='submit'>Přihlásit se k odběru</button>
        </form>
        <div className='eco-form-gdpr'>
          Přihlášením k newsletteru souhlasíte <a target='_blank' href='https://www.puxdesign.cz/cz/privacy-policy'>s našimi zásadami zpracování osobních údajů</a>.
        </div>
      </div>
    </div>
  </div>
)

export default PuxEcomailForm
