import axios from 'axios'
import React, { FunctionComponent } from 'react'
import getTokenData from '../../utils/getGraphqlValidationToken'

import Loader from '../../components/layout/Loader'
import Contact from '../contact'
import { ContactProps, ContactPost } from '../../types/contact.types'

const PreviewContact: FunctionComponent<any> = props => {
  const pageData = props.data.orchard.puxDesignContactPage[0]
  const [completeData, setCompleteData] = React.useState<any>()

  const query = `
  {
    puxDesignContactPage (where: {contentItemId: "${pageData.contentItemId}", contentItemVersionId: "${pageData.contentItemVersionId}"} status: DRAFT) {
      contentItemId
      path
      displayText
      puxDesignContactPageDisplayTitle
      puxDesignContactPageFormText
      puxDesignContactPageFormTitle
    }
  }
`

  React.useEffect(async () => {
    const tokenData = await getTokenData()

    axios
      .post(
        `${process.env.GATSBY_API_URL}`,
        {
          query: query,
        },
        {
          headers: {
            'Content-Type': `application/json`,
            'Access-Control-Allow-Origin': `*`,
            Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
          },
        }
      )
      .then(response => {
        if (response.status === 200) {
          const documentData: ContactPost = response.data.data.puxDesignContactPage[0]
          const data = { ...props }
          data.data.orchard.contact = [documentData]


          // TODO: remove when we will support multiple widget zones...
          data.pageContext.widgets = data.pageContext.widgets.widgetsPuxDesignContactPage

          // only for contact start
          // fake related articles
          // data.pageContext.relatedContactPosts.data.orchard.contact = [documentData]
          // only for contact end

          setCompleteData(data)
        }
      })
      .catch(error => {
        console.log(`Preview Data Fetch Error:`, error)
      })
  }, [])

  return completeData ? <Contact {...completeData} /> : <Loader message="Loading Article Draft Data" />
}

export default PreviewContact
