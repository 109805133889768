import React, { FunctionComponent, useContext } from 'react'
import { Link } from 'gatsby'
import Moment from 'react-moment'
import Picture from '../shared/picture'
import { BlogAuthor, BlogPostProps } from '../../types/blog.types'
import '../../../src/sass/blog/post.scss'
import { useInView } from 'react-intersection-observer'
import { motion } from 'framer-motion'
import { getHrefLang, getLocalizedUrl } from '../../utils/localeURL'

const BlogPost: FunctionComponent<BlogPostProps> = ({ type, props }) => {
  const getAuthor = (blogAuthor: BlogAuthor): string => {
    if (blogAuthor.contentItems == null || blogAuthor.contentItems == undefined) {
      return ``
    } else {
      return blogAuthor.contentItems[0].displayText
    }
  }

  const [ref, inView] = useInView({
    threshold: 0.3,
    triggerOnce: true,
  })

  const variants = {
    hidden: { y: 100, opacity: 0 },
    show: {
      y: 0,
      opacity: 1,
    },
  }

  if (type === `big`) {
    return (
      <motion.div
        className="post-wrapper"
        ref={ref}
        initial="hidden"
        animate={inView ? `show` : `hidden`}
        exit="hidden"
        variants={variants}
        transition={{ duration: 0.4, ease: `easeOut` }}
      >
        <Link className="post" hrefLang={getHrefLang(props.path)} to={getLocalizedUrl(props.path)}>
          <div className="post-image">
            {props.puxMediaSelector !== null && (
              <Picture puxMediaSelectorImage={props.puxMediaSelector.puxMediaSelectorImage} alt={props.displayText} />
            )}
          </div>
          <div className="post-content">
            <h2 className={`post-title`}>{props.displayText}</h2>
            <small className="post-info">
              <Moment format="DD - MM - YYYY" date={props.createdUtc} />
              <span> | </span>
              {getAuthor(props.blogAuthor)}
            </small>
            <div
              className="post-perex"
              dangerouslySetInnerHTML={{ __html: props.perex.html?.replace(/font-size: 1rem;/g, ``) }}
            ></div>
            <div className="post-cta">zobrazit články</div>
            <div className="post-tags">
              {props.puxCategory.termContentItems.map(item => (
                <div className="post-tag">
                  <span>{item.displayText}</span>
                </div>
              ))}
            </div>
          </div>
        </Link>
      </motion.div>
    )
  } else {
    return (
      <motion.div
        className="post-wrapper"
        ref={ref}
        initial="hidden"
        animate={inView ? `show` : `hidden`}
        variants={variants}
        exit="hidden"
        transition={{ duration: 0.4, ease: `easeOut` }}
      >
        <Link className="post" hrefLang={getHrefLang(props.path)} to={getLocalizedUrl(props.path)}>
          <div className="post-image">
            {props.puxMediaSelector !== null && (
              <Picture puxMediaSelectorImage={props.puxMediaSelector.puxMediaSelectorImage} alt={props.displayText} />
            )}
          </div>
          <div className="post-content">
            <h2 className="post-title h4">{props.displayText}</h2>
            <small className="post-info">
              <Moment format="DD - MM - YYYY" date={props.createdUtc} />
              <span> | </span>
              {getAuthor(props.blogAuthor)}
            </small>
            <div className="post-tags">
              {props.puxCategory.termContentItems.map(item => (
                <div className="post-tag">
                  <span>{item.displayText}</span>
                </div>
              ))}
            </div>
          </div>
        </Link>
      </motion.div>
    )
  }
}

export default BlogPost
