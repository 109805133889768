import React from 'react'
import { Link } from 'gatsby'
import { Icon } from '../components/builders/icon-builder'
import { getHrefLang, getLocalizedUrl } from '../utils/localeURL'

interface ServiceNavProps {
  contentItems: [
    {
      puxDesignServiceNavigationItemCustomDescription: string
      puxDesignServiceNavigationItemCustomTitle: string
      puxDesignServiceNavigationItemService: {
        contentItems: [
          {
            displayText: string
            puxDesignServicePageDescription: string
            path: string
            puxDesignIconSelector: {
              puxDesignIconSelectorIcon: string
            }
            puxDesignThemeSelector: {
              puxDesignThemeSelectorTheme: string
            }
          }
        ]
      }
    }
  ]
}

export default function ServiceNav(props: ServiceNavProps) {
  const theme = props.contentItems && props.contentItems[0]?.puxDesignServiceNavigationItemService.contentItems[0]
    ? props.contentItems[0]?.puxDesignServiceNavigationItemService.contentItems[0].puxDesignThemeSelector
      .puxDesignThemeSelectorTheme
    : ``

  if (!theme) {
    return null;
  }

  return props.contentItems ? (
    <div className={`sister-division-nav ${theme}`}>
      <div className="pux-container">
        <nav>
          <ul>
            {props.contentItems.map((service, index) => (
              <li key={index}>
                <Link
                  hrefLang={getHrefLang(service.puxDesignServiceNavigationItemService.contentItems[0].path)}
                  to={getLocalizedUrl(service.puxDesignServiceNavigationItemService.contentItems[0].path)}
                >
                  <div className="content">
                    <Icon
                      id={
                        service.puxDesignServiceNavigationItemService.contentItems[0].puxDesignIconSelector
                          .puxDesignIconSelectorIcon
                      }
                      color="#FFF"
                    ></Icon>
                    <big>
                      {service.puxDesignServiceNavigationItemCustomTitle
                        ? service.puxDesignServiceNavigationItemCustomTitle
                        : service.puxDesignServiceNavigationItemService.contentItems[0].displayText}
                    </big>
                    <div className='content-text'>
                      {service.puxDesignServiceNavigationItemCustomDescription
                        ? service.puxDesignServiceNavigationItemCustomDescription
                        : service.puxDesignServiceNavigationItemService.contentItems[0].puxDesignServicePageDescription}
                    </div>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  ) : null
}
