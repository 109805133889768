import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/layout/layout'
import PuxMetaTags from '../components/shared/PuxMetaTags'
import WidgetBuilder from '../components/builders/widget-builder'
import { t } from 'ttag'
import BreadCrumbs from '../components/BreadCrumbs'
import { getLocalizedUrl } from '../utils/localeURL'

const Career = ({ data, pageContext }: any) => {
  const page = data.orchard.puxDesignCareer[0]

  return (
    <Layout localizedPath={page.localization?.localizations}>
      <PuxMetaTags {...pageContext.metaTagsData} />
      <BreadCrumbs
        default={true}
        center={false}
        items={[
          {
            path: page.path,
            displayText: page.displayText,
          },
        ]}
      />
      <WidgetBuilder widgetBuilderData={pageContext.widgetsTop} />

      <section className="bg-grey triangle-cross">
        <div className="pux-container pb-80">
          <h2 className="center-content mb-30">{t`PuxDesign.Career.LookingFor`}</h2>
          {page.careerJobOffers?.contentItems.length ? (
            <div className="job-offers">
              {page.careerJobOffers?.contentItems.map((item: JobOfferProps) => (
                <JobOffer {...item} key={item?.contentItemId} />
              ))}
            </div>
          ) : (
            <div
              className="no-jobs-notice"
              dangerouslySetInnerHTML={{ __html: page.careerNoOffersText.html?.replace(/font-size: 1rem;/g, ``) }}
            ></div>
          )}
        </div>
      </section>

      <WidgetBuilder widgetBuilderData={pageContext.widgetsBottom} />
    </Layout>
  )
}

interface JobOfferProps {
  contentItemId: string
  jobOfferPreviewText: string
  displayText: string
  path: string
}

const JobOffer = (props: JobOfferProps) => (
  <Link to={getLocalizedUrl(props.path)}className="job-offer" key={props.contentItemId}>
    <h4 className="job-offer-title">{props.displayText}</h4>
    <div className="job-offer-description">{props.jobOfferPreviewText}</div>
    <span className="btn btn-primary">{t`PuxDesign.Career.ShowMore`}</span>
  </Link>
)

export default Career

export const query = graphql`
  query($pagePath: String = "") {
    orchard {
      puxDesignCareer(first: 1, where: { path: $pagePath }) {
        path
        displayText
        careerNoOffersText {
          html
        }
        localization {
          localizations {
            ... on Orchard_PuxDesignCareer {
              path
              published
              localization {
                culture
              }
            }
          }
        }
        careerJobOffers {
          contentItems {
            ... on Orchard_PuxDesignJobOffer {
              contentItemId
              jobOfferPreviewText
              path
              displayText
            }
          }
        }
      }
    }
  }
`
